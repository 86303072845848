import {css} from '@emotion/react'

import {WideContent} from '../../components/content'
import EmailLink from '../../components/EmailLink'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {COMPANY_NAME, EMAILS} from '../../constants'
import {backgroundWhiteCss, baseSectionCss} from '../../styles/common'

const termsCss = css`
  width: 1200px;
  font-size: 16px;

  p {
    text-align: left;
    font-size: inherit;
    max-width: 100%;
    margin: 16px 0;
  }

  h2 {
    font-size: 22px;
    text-align: left;
    margin: 0;

    ::after {
      display: none;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  address {
    font-style: normal;
    font-size: inherit;
    font-weight: 300;
  }

  li {
    font-size: inherit;
    font-weight: 300;

    a {
      color: #50afc6;
    }
  }

  > ol {
    padding-left: 20px;
  }

  dl {
    font-size: inherit;
    font-weight: 300;

    > div {
      margin: 16px 0;
    }

    dt,
    dd {
      display: inline;
    }

    dd {
      margin-inline-start: 0;
    }

    dt {
      font-weight: bold;
    }
  }
`

const subSectionLabelCss = css`
  margin-right: 10px;
`

const boldCss = css`
  font-weight: bold;
`

const underlineCss = css`
  text-decoration: underline;
`

export default function TermsOfServicePage(): JSX.Element {
  return (
    <Page title={<HeroSection short>Kensho Terms of Service</HeroSection>}>
      <PageMetadata
        title="Terms of Service | Kensho"
        description="These Terms of Service describe the terms and conditions that apply to customers of our services."
      />
      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent css={termsCss}>
          <p>Last Updated: March 6, 2025</p>
          <p>
            {COMPANY_NAME} (“
            <strong>Kensho</strong>” or “we”) offers machine learning, data science and statistical
            analysis software-as-a-service (SaaS) solutions that make data discoverable, useful and
            valuable for our customers (our “<strong>Services</strong>”). Kensho provides its
            Services subject to these Terms of Service (“<strong>Service Terms</strong>”)
          </p>
          <p>For Kensho’s Services:</p>
          <ul>
            <li>
              “<strong>Authorized User</strong>” is an employee, consultant, contractor, agent or
              other representative authorized by Customer to access and use the Services pursuant to
              these Service Terms.
            </li>
            <li>
              {' '}
              “<strong>Customer</strong>” is the natural or legal entity named in the Order (defined
              below) for the Services.
            </li>
          </ul>
          <p>
            When used in these Service Terms, “<strong>you</strong>” (and variants) means each of
            Customer and Customer’s Authorized User that accesses and uses the Services pursuant to
            these Service Terms.
          </p>

          <p>
            IMPORTANT NOTE: These Service Terms contain provisions that limit our liability to you
            and require you to resolve any dispute with us on an individual basis and not as part of
            any class or representative action.
          </p>
          <h2>1. Defined Terms</h2>
          <p>
            Capitalized terms not defined in these Service Terms have the meanings given in this
            Section 1:
          </p>
          <dl>
            <div>
              <dt>Beta Services</dt>
              <dd>
                {' '}
                means new functionality or components of the Services made available by Kensho from
                time to time for a Customer to try that are clearly identified as ‘beta’, ‘pilot’,
                ‘preview’, ‘evaluation’ or similar designation.
              </dd>
            </div>
            <div>
              <dt>Confidential Information</dt>
              <dd>
                {' '}
                means all information disclosed by a party to another party that is designated as
                confidential or that the receiving party should understand to be confidential given
                the nature of the information and the circumstances of disclosure.
              </dd>
            </div>
            <div>
              <dt>Customer Data</dt>
              <dd>
                {' '}
                means data in any form or media (including without implied limitation audio files)
                that is uploaded or linked to the Services by Customer or Authorized Users,
                processed through the Services by or on behalf of Customer or otherwise provided or
                made availableby or on behalf of Customer or Authorized Users in connection with the
                Services but excluding Usage Data and Third Party Materials. Customer Data expressly
                includes data brought to the Services by Customer or an Authorized User or to which
                Customer has access through the Services that are subject to an agreement by and
                between Customer and a third party (including an affiliate of Kensho).
              </dd>
            </div>
            <div>
              <dt>Customer Systems</dt>
              <dd> has the meaning given in Section 7.</dd>
            </div>
            <div>
              <dt>Documentation</dt>
              <dd>
                {' '}
                means all manuals, guides, code samples, files and technical documentation that
                Kensho provides or makes available to Customer in any form or medium that describe
                the functionality, components, features or requirements of the Services, as updated
                from time to time.
              </dd>
            </div>
            <div>
              <dt>Enterprise Agreement</dt>
              <dd> means the written agreement between Kensho and certain enterprise customers.</dd>
            </div>
            <div>
              <dt>Feedback</dt>
              <dd>
                {' '}
                means ideas, suggestions, concepts, proposals or other feedback about the Services
                submitted to Kensho by Customer or an Authorized User.
              </dd>
            </div>
            <div>
              <dt>Fees</dt>
              <dd>
                {' '}
                means the fees due to Kensho from Customer for access to and use of the Services.
              </dd>
            </div>
            <div>
              <dt>Free Trial</dt>
              <dd>
                {' '}
                means testing, trial and other evaluative (but not developmental or productive) use
                of the Services solely for the purpose of Customer’s evaluation of the Services
                prior to entering into an Order or other commitment to purchase a subscription to
                the Services.
              </dd>
            </div>
            <div>
              <dt>Order</dt>
              <dd>
                {' '}
                means the online order form specifying the Services purchased by Customer and to be
                provided by Kensho pursuant to these Service Terms, including amendments and
                supplements thereto.
              </dd>
            </div>
            <div>
              <dt>Registration Data</dt>
              <dd>
                {' '}
                means the names, email address, employer name and similar identification data that
                Kensho collects from Authorized Users to create accounts and otherwise enable access
                to and use of the Services.
              </dd>
            </div>
            <div>
              <dt>Service Plan</dt>
              <dd> means the specific Services that Customer purchases through an Order.</dd>
            </div>
            <div>
              <dt>Service Period</dt>
              <dd> has the meaning given in Section 13.</dd>
            </div>
            <div>
              <dt>Third-Party Materials</dt>
              <dd>
                {' '}
                means data, technology and/or services licensed or otherwise provided by a third
                party that Kensho makes available for access by Authorized Users through the
                Services.
              </dd>
            </div>
            <div>
              <dt>Usage Data</dt>
              <dd>
                {' '}
                means data related to Customer’s use of the Services (including Registration Data)
                that are used by Kensho for billing and account administration and to compile
                statistical and performance information related to the provision, operation and
                improvement of the Services.
              </dd>
            </div>
          </dl>
          <h2>2. How these Service Terms Apply.</h2>
          <p>
            These Service Terms apply to access to and use of the Services, including Beta Services
            and Free Trials.
          </p>
          <p>
            Certain Services may require that that Customer agree to additional terms. If any of the
            Service-specific terms conflict with these Service Terms, then the Service-specific
            terms will control to the extent of the conflict.
          </p>
          <p>
            An Enterprise Agreement may contain terms that apply to Customer and Authorized Users
            that are different from these Service Terms. If a provision of an Enterprise Agreement
            conflicts or otherwise is inconsistent with a provision of these Service Terms, then the
            term of the Enterprise Agreement will prevail to the extent of the conflict or
            inconsistency.
          </p>
          <p>
            The terms that apply to visitors to <Link to="/">www.kensho.com</Link> and affiliated
            domains are available at{' '}
            <Link to="https://www.spglobal.com/en/terms-of-use">
              https://www.spglobal.com/en/terms-of-use
            </Link>
            .
          </p>
          <h2>3. Updates to the Services.</h2>
          <p>
            Kensho continually innovates to improve the Services. As a result, Kensho may from time
            to time introduce corrections, modifications and other changes to the Services
            (collectively, “<strong>Updates</strong>”). Kensho also may introduce new features and
            functionality that enhance the Services and were not previously part of the Services as
            ordered in Customer’s Order (“<strong>New Features</strong>”). Customer hereby
            authorizes Kensho to implement Updates and New Features.
          </p>
          <p>
            Whenever reasonably possible, Kensho will provide Customer with advance notice of
            Updates that Kensho believes in good faith will materially reduce the performance,
            functionality, security or availability of the Services. Customer acknowledges and
            agrees that Updates to the Services made to comply with applicable law may prevent
            Kensho from providing advance notice. Customer is responsible for notifying Authorized
            Users about Updates and New Features.
          </p>
          <p>
            Kensho reserves the right to provide Updates and/or New Features without charge for the
            duration of Customer’s then-current Service Period but thereafter to increase Fees.
            Kensho will provide Customer a choice to use New Features and agree to corresponding
            supplements to these Service Terms and/or the Order. If Customer does not wish to use
            any of the New Features, then the corresponding new terms will not apply. If Kensho
            determines in its good-faith judgment that Kensho can no longer provide the Services
            without the New Features, then Kensho reserves the right to limit or terminate access to
            the Service or terminate these Service Terms upon written notice to Customer.
          </p>
          <h2>4. Changes to these Service Terms.</h2>
          <p>
            Customer acknowledges and agrees that Kensho has the right to modify these Service Terms
            from time to time to reflect Updates, New Features or other changes to the Services or
            applicable law. Kensho will notify Customer at least ten (10) business days in advance
            of material modifications to these Service Terms that reduce Customer’s legal rights.
            Kensho will make these notifications by email using the email address in the Order or
            otherwise as required in an Enterprise Agreement and through notices posted within the
            Services. Continued use of the Services after the end of the notice period specified in
            the notification is deemed acceptance of the Service Terms as modified. If Customer does
            not agree to the Service Terms as modified, then Customer must cancel as described in
            Section 13.3 and the then-current Service Terms will apply to Customer’s use of the
            Services until the end of the then-current Service Term.
          </p>
          <h2>5. Accounts and Access to the Services.</h2>
          <p>
            The Services require an account in Customer’s name and (except as provided below) for
            each Authorized User. When creating an account, each Authorized User must (i) choose a
            username and password (“<strong>Account Credentials</strong>”); (ii) provide accurate,
            current and complete information and maintain that information as accurate, current and
            complete; and (iii) use appropriate safeguards to maintain the confidentiality and
            security of Account Credentials. Certain Authorized Users may not require Account
            Credentials because they have access to the Services by a single sign-on authentication
            method through Customer Systems.
          </p>
          <p>
            Authorized Users are not permitted to share Account Credentials or to allow any
            unauthorized person to access the Services by any means. Kensho is not responsible for
            costs, loss or other damages arising from unauthorized use of Account Credentials.
          </p>
          <p>
            Kensho reserves the right to terminate Account Credentials and/or corresponding access
            to the Services if Kensho believes that account information is inaccurate or Account
            Credentials or the Services were used by an unauthorized person or otherwise are not
            secure.
          </p>
          <p>
            Please immediately notify Kensho at <EmailLink email={EMAILS.SECURITY} /> if
            unauthorized activity is suspected or detected.
          </p>
          <h2>6. Use of the Services.</h2>
          <p>
            Subject to and conditioned on compliance with these Service Terms by Customer and its
            Authorized Users, Kensho hereby grants to Customer a limited, revocable, non-exclusive,
            non-transferable, worldwide right during the Service Period to permit its Authorized
            Users to access and use the Services and Documentation solely for Customer’s internal
            business purposes. Kensho reserves all rights that are not expressly granted. Kensho
            makes the Services available in compliance with laws applicable to Kensho and not
            necessarily in compliance with laws applicable to Customer’s specific use of the
            Services.
          </p>
          <p>
            Customer grants to Kensho the non-exclusive, non-transferable, worldwide right to host,
            use, process, display and transmit Customer Data to provide the Services and otherwise
            in accordance with these Service Terms and each Order.
          </p>
          <h2>7. Customer Responsibilities.</h2>
          <p>Customer is solely responsible for:</p>
          <ul>
            <li>The accuracy of the Order, including the Services selected therein;</li>
            <li>
              Compliance with these Service Terms, including compliance by each Authorized User;
            </li>
            <li>All decisions, actions and failures to act based on use of the Services;</li>
            <li>
              Compliance with all applicable laws and regulations related to use of the Services by
              Customer and its Authorized Users;
            </li>
            <li>
              The quality, integrity, reliability and lawfulness of all Customer Data, including
              data provided by a third party for use by Customer that are part of Customer Data;
            </li>
            <li>Creating backup copies of Customer Data at Customer’s sole cost and expense;</li>
            <li>
              Consents and notices required for the processing of personal information of
              identifiable individuals that are part of Customer Data and otherwise processing
              personal information in accordance with applicable law;
            </li>
            <li>
              The information technology infrastructure through which Customer accesses and uses the
              Services, including hardware and other equipment, software, networks and internet
              connectivity (and associated fees), whether operated directly by Customer or through
              the use of third-party services (“<strong>Customer Systems</strong>”), all access to
              and use of the Services directly or indirectly through the Customer Systems (whether
              or not authorized) and the operation, maintenance and management of the Customer
              Systems;
            </li>
            <li>
              The security and use of Access Credentials associated with Customer and Authorized
              Users and all activities that occur through those Account Credentials; and
            </li>
            <li>
              Use of reasonable and appropriate administrative, physical and technical safeguards to
              protect against unauthorized access to or use of the Services and Customer Data.
            </li>
          </ul>
          <h2>8. Use Conditions and Restrictions.</h2>
          <p>
            Customer understands and agrees and shall ensure that each Authorized User understands
            and agrees to the following conditions and restrictions.
          </p>
          <ul>
            <li>
              Kensho reserves the right but has no obligation to access and screen Customer Data for
              violation of these Service Terms or law and reserves the right in its sole discretion
              to request removal, disable or remove Customer Data that are illegal, violate these
              Service Terms or disrupt or threaten the Services. If Kensho removes or disables
              Customer Data, Kensho will provide notice to Customer unless prohibited by law.
            </li>
            <li>
              Kensho may disclose Customer Data, including the content of communications stored on
              Kensho’s systems, to perform the Services and (i) if Kensho believes that disclosure
              is reasonably necessary to comply with any law or a judicial, regulatory or law
              enforcement demand, order or request that Kensho believes in good faith is lawful,
              (ii) to perform and enforce Kensho’s agreements and policies, including for billing,
              (iii) to protect the security or integrity of the Services, (iv) to protect Kensho,
              other customers or the public from harm or illegal activities, and (v) to respond to
              an emergency that Kensho believes in good faith requires us to disclose Customer Data
              to assist in preventing a death or serious bodily injury.
            </li>
            <li>
              Kensho is not obligated to back up Customer Data or Third-Party Materials. To the
              extent that Customer Data is stored in the Services, Kensho retains the right to
              create reasonable limits on storage of Customer Data, such as limits on file size,
              storage space, processing capacity and similar limits. Except as otherwise agreed in
              writing, Kensho may periodically delete Customer Data and Kensho has no liability
              whatsoever for damages, liabilities, losses or any other consequence relating to
              Kensho’s deletion of Customer Data.
            </li>
            <li>
              Kensho monitors the Services to facilitate operation of the Services, to help resolve
              support requests, to detect and address threats to the functionality, security,
              integrity and availability of the Services, to detect and address illegal acts or
              violations of these Service Terms and for license management purposes.
            </li>
          </ul>
          <p>
            For purposes of clarity and without limiting the generality of the foregoing, Customer
            shall not, except as these Service Terms expressly permit:
          </p>
          <ul>
            <li>
              copy, modify, frame, mirror or create derivative works or improvements of the
              Services;
            </li>
            <li>
              rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or
              otherwise make available any of the Services to any third party, including on or in
              connection with the internet or any time-sharing, service bureau, software as a
              service, cloud or other technology or service;
            </li>
            <li>
              reverse engineer, disassemble, decompile, decode, adapt or otherwise attempt to derive
              or gain access to the source code of the Services, in whole or in part;
            </li>
            <li>
              bypass or breach any security device or protection used by the Services, access or use
              the Services other than through the use of then-valid Access Credentials or perform
              any vulnerability testing or scanning without Kensho’s approval;
            </li>
            <li>
              input, upload, transmit or otherwise provide to or through the Services, any
              information or materials that are unlawful or injurious or contain, transmit or
              activate any virus, worm, malware or other malicious computer code;
            </li>
            <li>
              damage, destroy, disrupt, disable, impair or otherwise impede or harm in any manner
              the Services or Kensho’s provision of services to any third party, in whole or in
              part;
            </li>
            <li>
              remove, delete, alter or obscure any copyright, trademark, patent or other
              intellectual property or proprietary rights notices from the Services or Third-Party
              Materials, including any copy thereof;
            </li>
            <li>
              access or use the Services or Third-Party Materials in any manner or for any purpose
              that infringes, misappropriates or otherwise violates any right of any third party
              (including by any unauthorized access to, misappropriation, use, alteration,
              destruction or disclosure of the data of any other customer) or that violates any
              applicable law;
            </li>
            <li>
              access or use the Services for purposes of competitive analysis of the Services, the
              development, provision or use of a competing product or service or any other purpose
              that is to Kensho’s detriment or commercial disadvantage;
            </li>
            <li>
              use the Services in the operation of any hazardous environments or critical systems
              that may lead to serious bodily injury or death or cause environmental or property
              damage; or
            </li>
            <li>
              otherwise access or use the Services beyond the scope of the authorization expressly
              granted under these Service Terms.
            </li>
          </ul>

          <h2>9. Ownership and Intellectual Property Rights.</h2>
          <p>
            <span css={subSectionLabelCss}>9.1</span> As between Customer and Kensho, Customer is
            and will remain the sole and exclusive owner of all right, title and interest in and to
            all Customer Data and the Customer Systems, including all intellectual property rights
            relating thereto. Customer hereby irrevocably grants to Kensho all rights and
            permissions in or relating to Customer Data as Kensho determines necessary to perform
            the Services and Kensho’s obligations, to enforce these Service Terms or exercise
            Kensho’s rights and to develop and improve its machine-learning and
            artificial-intelligence technologies.
            <em>
              Customer expressly acknowledges and agrees that Customer Data excludes Usage Data
            </em>
            .
          </p>
          <p>
            <span css={subSectionLabelCss}>9.2</span> As between Customer and Kensho, Kensho and its
            affiliates and their respective third-party licensors are and will remain the sole and
            exclusive owners of all right, title and interest in and to the Services, including all
            content available through the Services, and all intellectual property rights therein,
            but excluding Customer Data and Customer Systems. Customer shall not cause any third
            party to violate or infringe the copyrights, trade secrets, trademarks and other
            proprietary rights of Kensho or its third-party licensors in the Services, including any
            contractual, statutory or common-law rights, at any time whether before, during or after
            the Service Period. In furtherance of the foregoing, Customer and each Authorized User
            hereby unconditionally and irrevocably grants to Kensho all right, title and interest
            (if any) in and to Usage Data.
          </p>
          <p>
            <span css={subSectionLabelCss}>9.3</span> Customer and each Authorized User hereby grant
            to Kensho a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive
            and fully sub-licensable right and license to use, reproduce, perform, display,
            distribute, adapt, modify, re-format, create derivative works of and otherwise exploit
            in any manner all Feedback and also acknowledge and agree that Kensho may use Feedback
            on the Site and in promotional materials as long as none of Customer or an Authorized
            User are directly identified without prior written permission.
          </p>
          <p>
            <span css={subSectionLabelCss}>9.4</span> All right, title and interest, including all
            intellectual property rights, in and to Third-Party Materials are owned by the
            applicable third party. Customer has no right, license or authorization with respect to
            Third-Party Materials except as expressly set forth in the applicable third-party
            license and these Service Terms. All other rights in and to the Third-Party Materials
            are expressly reserved by the applicable third-party licensor.
          </p>
          <p>
            <span css={subSectionLabelCss}>9.5</span> Customer shall not use or display any of the
            name or logos of Kensho or its affiliates or the providers of Third Party Materials (as
            between Customer and Kensho, “<strong>Kensho Marks</strong>”) without Kensho’s prior
            written consent to each such use. Customer agrees not to use, register or take other
            action with respect to the Kensho Marks unless expressly agreed in writing or to add to,
            delete from or modify the Kensho Marks or misrepresent the relationship between Kensho
            and Customer.
          </p>
          <h2>10. Free Trials and Beta Services.</h2>
          <p>
            In addition to paid services, these Service Terms govern Free Trials and Beta Services
            unless otherwise stated, which means that in order to use the Services, even on a free
            and unpaid basis, you agree to comply with the Service Terms. If you do not agree, you
            should leave this site and refrain from any further use of the Services. By continuing
            to use the Services, you understand that you agree to such Service Terms.
          </p>
          <p>
            <span css={subSectionLabelCss}>10.1</span> <span css={boldCss}>Free Trials.</span>{' '}
            Kensho may provide Customer with access to the Services for a specified Free Trial
            period. During a Free Trial, Kensho will make the applicable Services available free of
            charge until the end of the Trial period unless otherwise terminated by Kensho in its
            sole discretion.
          </p>
          <p>
            Upon registration for a Free Trial, Kensho will require Customer to provide and verify a
            valid email address prior to the beginning of the Free Trial. Customer may cancel use of
            the Services at any time during the Free Trial period.{' '}
            <strong>
              At the end of the Free Trial period, if applicable, Fees are automatically charged
              based on the Order (if any). Otherwise, Customer may subscribe to use of the Services
              and access to the Services will automatically continue on a paid month-to-month basis
              based on Authorized User’s (or Users’) use of the Services pursuant to these Service
              Terms (excluding this Section 10) and the terms of any Order with respect to the
              selected Service.
            </strong>
          </p>
          <p>
            To cancel, please send an email before the Free Trial period expires to{' '}
            <EmailLink email={EMAILS.SUPPORT} />. Please use the subject line “Free Trial
            Cancellation” and add “Service Cancellation” and Customer’s legal name (as set forth in
            the Order Form) in the body of the email.
          </p>
          <p>
            <span css={subSectionLabelCss}>10.2</span> <span css={boldCss}>Beta Services.</span>{' '}
            Customer and its Authorized Users may access and use Beta Services solely for internal,
            testing, demonstration, trial and other evaluative (but not any developmental,
            commercial, or product creation) purposes, including to assess the Beta Services’
            compatibility with the Customer System or business needs. Customer agrees to provide and
            request that each Authorized User provide any requested or voluntary Feedback about the
            Beta Services to Kensho, and Customer agrees that Kensho shall own any rights, title,
            and interest in any such Feedback, provided that such Feedback is used in an aggregated
            and depersonalized format. Kensho reserves the right to modify Beta Services without
            notice until Beta Services are deemed part of the Services and Kensho may decide not to
            make certain Beta Services part of the Services and may do so without any liability to
            Customer or its Authorized Users.
          </p>
          <p>
            <span css={subSectionLabelCss}>10.3</span>{' '}
            <span css={boldCss}>
              WAIVERS AND DISCLAIMERS RELATED TO FREE TRIALS AND BETA SERVICES.
            </span>{' '}
            NOTWITHSTANDING THE WARRANTIES AND DISCLAIMERS, INDEMNIFICATION AND LIMITATIONS OF
            LIABILITY SECTIONS ELSEWHERE IN THESE SERVICE TERMS, SERVICES MADE AVAILABLE DURING A
            FREE TRIAL OR WITH RESPECT TO BETA SERVICES ARE PROVIDED “AS-IS” WITHOUT ANY WARRANTY OF
            ANY KIND, AND KENSHO AND ITS AFFILIATES AND THIRD-PARTY LICENSORS (“
            <strong>KENSHO PARTIES</strong>”) SHALL HAVE NO INDEMNIFICATION OBLIGATIONS OR LIABILITY
            OF ANY KIND ARISING FROM USE OF A FREE TRIAL OR BETA SERVICES. IF ANY EXCLUSION OF
            LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW, THE SOLE LIABILITY OF THE KENSHO
            PARTIES WITH RESPECT TO THE FREE TRIAL OR BETA SERVICES IS FOR CUSTOMER’S DIRECT DAMAGES
            UP TO THE GREATER OF THE AMOUNT PAID BY CUSTOMER DURING THE CALENDAR MONTH PRECEDING THE
            EVENT THAT GAVE RISE TO THE LIABILITY OR $100, WHICHEVER IS GREATER.
          </p>
          <p>
            WITHOUT LIMITING THE FOREGOING, KENSHO PARTIES DO NOT REPRESENT OR WARRANT TO CUSTOMER
            THAT: (A) CUSTOMER’S FREE TRIAL OR USE OF BETA SERVICES WILL MEET CUSTOMER’S
            REQUIREMENTS OR (B) CUSTOMER’S FREE TRIAL OR USE OF BETA SERVICES WILL BE UNINTERRUPTED,
            TIMELY, SECURE, OR FREE FROM ERROR. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE
            SERVICE TERMS, CUSTOMER IS FULLY LIABLE TO KENSHO AND THE KENSHO PARTIES UNDER THESE
            SERVICE TERMS FOR DAMAGES ARISING OUT OF OR RELATED TO USE OF THE SERVICES BY CUSTOMER
            AND ITS AUTHORIZED USERS AND ANY BREACH BY CUSTOMER OF THESE SERVICE TERMS AND ANY OF
            CUSTOMER’S INDEMNIFICATION OBLIGATIONS HEREUNDER DURING A FREE TRIAL OR WITH RESPECT TO
            BETA SERVICES.
          </p>
          <h2>11. Privacy and Data Protection.</h2>
          <p>
            <span css={subSectionLabelCss}>11.1</span> <span css={boldCss}>Privacy Policy.</span> To
            the extent that Kensho processes personal information that is Customer Data, Kensho will
            comply with the Privacy Policy available at{' '}
            <Link to="https://www.spglobal.com/en/privacy/privacy-policy-english">
              https://www.spglobal.com/en/privacy/privacy-policy-english
            </Link>
            . For personal information that is Customer Data, the terms of Kensho affiliate, S&P’s
            Data Processing Addendum apply.
          </p>
          <p>
            <span css={subSectionLabelCss}>11.2</span> <span css={boldCss}>Security.</span> Kensho
            uses administrative, physical and technical safeguards intended to protect the Services
            and Customer Data. Our safeguards are designed to provide a level of security
            appropriate to the risk and include (as applicable) measures to ensure the ongoing
            confidentiality, integrity, availability and resilience of processing systems and a
            procedure for regularly testing, assessing and evaluating the effectiveness of technical
            and organizational measures for ensuring the security of the processing. Unless
            otherwise specified in the relevant Order, Customer Data may not include any sensitive
            or special data that imposes any new, specific security obligations on Kensho.
          </p>
          <h2>12. Customer Confidentiality.</h2>
          <p>
            The parties agree that Customer Data is Confidential Information of Customer and that
            Documentation, Orders, the Services and their content (excluding Customer Data) and
            Usage Data are Kensho’s Confidential Information.
          </p>
          <p>
            Each party agrees that it will use the Confidential Information of the other party in
            accordance with these Service Terms and it will not use, access, or permit the use or
            access of Confidential Information of the other party except as necessary to exercise
            its rights or perform its obligations in accordance with these Service Terms or as
            expressly permitted by the disclosing party in writing. Each party agrees to exercise
            due care in protecting the Confidential Information of the other party from unauthorized
            use and disclosure. Each party may disclose the Confidential Information of the other
            party, in whole or in part to its employees, representatives, actual or potential
            investors, and subcontractors who have a need to know and are legally bound to keep such
            information confidential consistent with these Service Terms. Either party may disclose
            the Confidential Information of the other party as required by law, upon prior written
            notice to the other party (where allowed by law); provided that such party will use its
            reasonable efforts to minimize such disclosure to the extent permitted by applicable
            law.
          </p>
          <p>
            In these Service Terms, Confidential Information does{' '}
            <span css={underlineCss}>not</span> include any information that: (i) is publicly
            available through no fault of the receiving party; (ii) was known or disclosed to the
            receiving party, without restriction, prior to disclosure by the disclosing party; (iii)
            was disclosed to the receiving party, without restriction, by another person without
            violation of the disclosing party’s rights; or (iv) is independently developed by the
            receiving party without use of or reference to the disclosing party’s Confidential
            Information. Customer expressly acknowledges and agrees that disclosure of Customer Data
            in connection with fraud detection or prevention is not a breach of this Section 12.
          </p>
          <p>
            Kensho and Customer each expressly acknowledge and agree that no adequate remedy exists
            at law for an actual or threatened breach of this Section 12 and that, in the event of
            an actual or threatened breach of the provisions of confidentiality, the non-breaching
            party is entitled to seek immediate injunctive and other equitable relief, without the
            requirement to post bond and without waiving any other rights or remedies available to
            it. Each party will promptly notify the other in writing if it becomes aware of any
            violation of these confidentiality obligations.
          </p>
          <h2>13. Service Period and Termination.</h2>
          <p>
            <span css={subSectionLabelCss}>13.1</span> <span css={boldCss}>Service Period.</span>{' '}
            The term of Customer’s right to permit Authorized Users to access and use the Services
            begins and ends on the date specified in the Order or Enterprise Agreement (“
            <strong>Initial Term</strong>”). If Customer does not cancel before the end of a Free
            Trial, the Initial Term begins on the first full calendar day after the date on which
            the Free Trial ends and continues on a month-to-month basis thereafter. Customer’s
            subscription to the Services automatically renews for successive periods equal in length
            to the Initial Term (each, a “<strong>Renewal Term</strong>”), unless otherwise
            specified in the Order or Enterprise Agreement or Customer cancels in accordance with
            the procedures set forth in Section 13.3 of these Service Terms. The Initial Term and
            all Renewal Terms (if any) are together the “<strong>Service Period</strong>.”
          </p>
          <p>
            <span css={subSectionLabelCss}>13.2</span>{' '}
            <span css={boldCss}>Termination or Suspension by Kensho.</span> In addition to the
            termination rights elsewhere in these Service Terms, Kensho reserves the right to
            terminate or suspend access to the Services immediately if Kensho determines in
            good-faith that: (a) Customer or any Authorized User violated these Service Terms (or
            Kensho reasonably believes a violation has occurred); (b) providing the Services is
            prohibited by law or has become impractical or unfeasible for any legal or regulatory
            reason; (c) Customer has experienced a liquidation, commencement of dissolution
            proceedings, disposal of assets or change of control, a failure to continue business,
            assignment for the benefit of creditors or Customer became the subject of bankruptcy or
            similar proceeding; (d) use of the Services by Customer or its Authorized Users
            threatens the availability, integrity, resilience or security of the Services; or (e)
            Customer has not paid the applicable Fees as and when due. Any suspension of the
            Services shall not excuse Customer’s obligation to pay Fees.
          </p>
          <p>
            <span css={subSectionLabelCss}>13.3</span>{' '}
            <span css={boldCss}>Termination without Cause.</span> Either party may terminate
            Customer’s and Authorized Users’ right to access and use the Services and these Service
            Terms by providing the other party with at least thirty (30) days’ prior written notice,
            with termination to take effect at the end of the Service Period in which the notice
            period concludes.{' '}
            <strong>
              To be effective, Customer’s termination notice must be sent to{' '}
              <EmailLink email={EMAILS.LEGAL} />.
            </strong>
          </p>
          <p>
            <span css={subSectionLabelCss}>13.4</span>{' '}
            <span css={boldCss}>Notice of Suspension or Termination.</span> If Kensho suspends
            access to the Services, Kensho will make a reasonable attempt to notify Customer and, if
            the violation is remedied to Kensho’s reasonable satisfaction, restore access to the
            Services. If Kensho terminates Customer’s access to the Services, Kensho will send a
            notice using the contact information provided by Customer in the Order. Kensho shall not
            be liable to Customer (including, for clarification, any Authorized User) or any third
            party for any suspension or termination that complies with these Service Terms.
          </p>
          <p>
            <span css={subSectionLabelCss}>13.5</span>{' '}
            <span css={boldCss}>Effect of Termination.</span> Upon expiration of any earlier
            termination of the Service Period, Customer’s and each Authorized User’s right to use
            the Services automatically terminates and all Customer Data associated with Customer’s
            account may be deleted. Kensho has no liability whatsoever for deletion of Customer
            Data. Kensho may retain Customer Data stored in standard archival or computer back-up
            systems; for litigation and regulatory purposes; or to the extent required by law and in
            each case pursuant to Kensho’s record retention policy requirements.
          </p>
          <p>
            The terms of this Section 13 and the terms of the following Sections will survive
            termination: Section 11 (for as long as Kensho holds personal information that is
            Customer Data) and Sections 9, 10 through 12, 16 through 18 and 22.
          </p>
          <h2>14. Fees and Payment Terms.</h2>
          <p>
            <span css={subSectionLabelCss}>14.1</span> <span css={boldCss}>Fees.</span> Customer
            agrees to pay the Fees set forth in the Order when they are due and using the payment
            method set forth in the Order. All Fee are due in US Dollars. Unless otherwise agreed in
            writing or in an Order, Fees are billed monthly based on use of the Services by
            Authorized Users. Except as set forth in these Service Terms or an Order, an executed
            Order is non-cancelable and all Fees are non-refundable. If the total number of
            Authorized Users is set forth in an Order and if the number of Authorized Users exceeds
            the number set forth in the Order, then the Fees are subject to an appropriate increase
            to reflect the increased number of Authorized Users. Fees set forth in an Order are not,
            however, reduced if the number of Authorized Users is less than the number set forth in
            an Order. Unless expressly agreed otherwise in writing with Kensho, Kensho reserves the
            right to increase Fees applicable to any Renewal Term upon at least sixty (60) days’
            prior written notice to Customer.
          </p>
          <p>
            <span css={subSectionLabelCss}>14.2</span>{' '}
            <span css={boldCss}>Credit Card Payment.</span> Customer agrees that Stripe, Kensho’s
            third-party credit card processor, will charge Customer’s credit card on behalf of
            Kensho as set forth in the Order. If the credit card provided is declined for any
            reason, then Kensho reserves the right to suspend access to the Services. Please see{' '}
            <Link to="https://stripe.com/privacy">https://stripe.com/privacy</Link> for more
            information about Stripe’s data practices.
          </p>
          <p>
            <span css={subSectionLabelCss}>14.3</span> <span css={boldCss}>Invoicing.</span> If
            Kensho agrees in writing to send invoices to Customer and Customer pays in arrears, then
            Kensho will send monthly invoices (as PDF) to Customer via email to the email designated
            in the Order. Customer will pay Fees hereunder within thirty (30) days of the date of
            the invoice in United States dollars, unless otherwise agreed in writing.
          </p>
          <p>
            <span css={subSectionLabelCss}>14.4</span> <span css={boldCss}>Late Fees.</span> If
            Customer is overdue on any payment of Fees and fails to pay within ten (10) business
            days of Kensho’s written notice of overdue Fees, then Kensho may assess and Customer
            must pay a late charge of one and one-half percent (1.5%) per month or the maximum
            amount allowable by applicable law, whichever is less. Following the notice of
            non-payment, Kensho also may suspend the Services until Customer pays the overdue Fees
            plus late fees. Kensho will have no liability for any damage, liability, loss (including
            any loss of data or profits) or any other consequence that Customer may incur if Kensho
            suspends Customer’s access to the Services pursuant to this Section 14.4.
          </p>
          <p>
            <span css={subSectionLabelCss}>14.5</span> <span css={boldCss}>Fee Disputes.</span> If
            Customer has a good-faith dispute about Fees, Kensho and Customer agree to cooperate
            diligently to resolve the dispute. Customer must notify Kensho at{' '}
            <EmailLink email={EMAILS.PAYMENT} /> in writing of any Fees-related dispute within sixty
            (60) days of the date on which Customer is charged or invoiced, as applicable.
          </p>
          <h2>15. Taxes.</h2>
          <p>
            Kensho’s Fees are exclusive of any applicable taxes, whether these taxes are imposed
            directly on Customer or on Kensho (collectively, the “<strong>Taxes</strong>”). If any
            of the Services or payments for any of the Services under the Service Terms are subject
            to Taxes in any jurisdiction and Customer has not remitted the applicable Taxes to
            Kensho, Customer is responsible for the payment of such Taxes and any related penalties
            or interest to the relevant tax authority, and Customer will indemnify Kensho for any
            liability or expense Kensho may incur in connection with such Taxes. Upon Kensho’s
            request, Customer will provide Kensho with official receipts issued by the appropriate
            taxing authority, or other such evidence that Customer has paid all applicable Taxes.
            For purposes of this Section, “<strong>Sales Tax</strong>” shall mean any sales or use
            tax, and any other tax measured by sales proceeds, that Kensho is permitted to pass to
            its customers, that is the functional equivalent of a sales tax where the applicable
            taxing jurisdiction does not otherwise impose a sales or use tax. For purposes of this
            section, Taxes do not include any taxes that are imposed on or measured by the net
            income, property tax or payroll taxes of Kensho.
          </p>
          <h2>16. Warranties; Disclaimers; Release.</h2>
          <p>
            <span css={subSectionLabelCss}>16.1</span>{' '}
            <span css={boldCss}>Customer Warranties.</span> Customer represents and warrants to
            Kensho that (i) Customer has all necessary rights and consents to use Customer Data
            (including in particular any third-party data that is part of Customer Data) and grant
            Kensho the right to use Customer Data as required or permitted in these Service Terms;
            and (ii) Customer will comply and ensure its Authorized Users comply with these Service
            Terms and all applicable laws related to Customer’s use of the Services.
          </p>
          <p>
            <span css={subSectionLabelCss}>16.2</span> <span css={boldCss}>Kensho Warranties.</span>{' '}
            Kensho represents and warrants to Customer that the Services will perform materially in
            accordance with the Documentation and do not violate the intellectual property rights of
            a third party when used in accordance with these Services Terms and the Documentation.
            Customer’s sole and exclusive remedy if Kensho breaches the foregoing warranty is, at
            Kensho’s option, to re-perform the affected Services or refund to Customer the Fees
            actually paid for the affected Services during the duration of the non-performance
          </p>
          <p>
            <span css={subSectionLabelCss}>16.3</span> <span css={boldCss}>Disclaimer.</span> EXCEPT
            AS EXPRESSLY PROVIDED IN THESE SERVICE TERMS, THE SERVICES ARE PROVIDED “AS IS” AND “AS
            AVAILABLE” AND KENSHO PARTIES DISCLAIM ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED,
            INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE, AVAILABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT THIS DISCLAIMER CONFLICTS WITH
            APPLICABLE LAW, THE SCOPE AND DURATION OF ANY APPLICABLE WARRANTY WILL BE THE MINIMUM
            PERMITTED UNDER THAT LAW.
          </p>
          <p>
            KENSHO PARTIES MAKE NO WARRANTY, REPRESENTATION, OR CONDITION THAT: (1) THE SERVICES
            WILL MEET CUSTOMER’S REQUIREMENTS; (2) USE OF THE SERVICES WILL BE UNINTERRUPTED,
            TIMELY, SECURE OR ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
            SERVICES WILL BE ACCURATE OR RELIABLE; (4) ANY ERRORS IN THE SERVICES WILL BE CORRECTED;
            OR (5) KENSHO WILL CONTINUE TO OFFER THE SERVICES IN WHOLE OR IN PART.
          </p>
          <p>
            <span css={subSectionLabelCss}>16.4</span> <span css={boldCss}>Release.</span> Customer
            for itself and each Authorized User hereby releases the Kensho Parties and each of their
            respective officers, directors, investors, shareholders, members, employees, agents and
            permitted successors and assigns from claims, demands, losses, damages, rights, and
            actions of any kind (including personal injury, death and property damage) (each, a “
            <strong>Claim</strong>”) that directly or indirectly relates to or arises from use of
            the Services by Customer or its Authorized Users, including with respect to third-party
            data that Customer uses in connection with the Service and any interaction with or
            conduct of other users or third-party websites of any kind arising in connection with or
            as a result of these Service Terms or use of the Services.
          </p>
          <p>
            If you are a California resident, you hereby expressly waive any rights you may have
            under California Civil Code Section 1542, which states “A general release does not
            extend to claims which the creditor does not know or suspect to exist in his favor at
            the time of executing the release, which, if known by him must have materially affected
            his settlement with the debtor.”
          </p>
          <h2>17. Indemnification.</h2>
          <p>
            Customer will defend, indemnify, and hold Kensho Parties and their respective officers,
            directors, employees and agents harmless from and against any actual or threatened
            claim, third-party discovery demand, governmental investigation or enforcement action
            arising out of or relating to breach of Customer’s warranties in Section 16.1.
          </p>
          <p>
            Kensho will indemnify and hold Customer and its officers, directors, employees, and
            agents harmless from damages, liabilities, costs and expenses finally awarded by a court
            of competent jurisdiction to a third party claiming that the Services infringe that
            third party’s intellectual property rights.
          </p>
          <p>
            Each indemnified party will cooperate as fully as reasonably required in the defense of
            any indemnified claim, at the indemnifying party’s expense. The indemnified party
            reserves the right, at the indemnifying party’s expense, to retain separate counsel in
            connection with an indemnified claim or, if the indemnifying party has not responded
            reasonably to the indemnified claim, to assume the exclusive defense and control of the
            indemnified claim in which the indemnifying party is a named party and that is otherwise
            subject to indemnification under this Section 17. The indemnifying party will pay all
            costs, expenses, reasonable attorneys’ fees, government fines and penalties, settlement
            amounts and other damages incurred by the indemnified party in connection with an
            indemnified claim. The indemnifying party also is liable to the indemnified party for
            all costs and reasonable attorneys’ fees that the indemnified party incurs to establish
            or enforce its right to indemnification under this Section 17. The indemnifying party
            agrees that this Section 17 survives the expiration or any earlier termination of these
            Service Terms.
          </p>
          <h2>18. Exclusion of Damages; Limitations of Liability.</h2>
          <p>
            EXCEPT FOR LIABILITY ARISING FROM VIOLATIONS OF SECTIONS 7, 8, 9, 12, OR 16.1, OR FROM A
            PARTY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OR FROM PERSONAL INJURY OR DEATH ARISING
            FROM USE OF THE SERVICES IN COMPLIANCE WITH THESE SERVICE TERMS, UNDER NO CIRCUMSTANCES
            AND UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT OR OTHERWISE, WILL EITHER PARTY OR
            ITS AFFILIATES BE LIABLE TO THE OTHER PARTY OR ITS AFFILIATE FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY CHARACTER, INCLUDING DAMAGES FOR
            LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS, WORK STOPPAGE, COMPUTER FAILURE
            OR MALFUNCTION OR LOST DATA, EVEN IF SUCH PARTY HAD BEEN ADVISED, KNEW OR SHOULD HAVE
            KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            EXCEPT FOR CLAIMS ARISING FROM KENSHO’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, UNDER NO
            CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT OR OTHERWISE, WILL
            KENSHO PARTIES BE LIABLE TO CUSTOMER OR ANY AUTHORIZED USER FOR ANY DIRECT DAMAGES,
            COSTS OR LIABILITIES IN EXCESS OF THE AMOUNTS PAID BY CUSTOMER TO KENSHO DURING THE
            TWELVE (12) MONTHS PRECEDING THE INCIDENT THAT GAVE RISE TO THE LIABILITY OR CLAIM OR
            $1,000, WHICHEVER IS GREATER. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
            ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KENSHO AND CUSTOMER.
          </p>
          <p>
            CERTAIN JURISDICTIONS HAVE STATUTES THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES. If these Service Terms and the term of a statute applicable to Customer
            conflict, then the term of the statute shall prevail but solely with respect to the
            conflict.
          </p>
          <h2>19. Export Controls.</h2>
          <p>
            The Services may be subject to US export control laws, including the US Export Control
            Reform Act and its associated regulations. Customer will not and will not permit an
            Authorized User to directly or indirectly, export, re-export or release the Services to,
            or make the Services accessible from, any country, jurisdiction or third party to which
            export, re-export or release is prohibited by applicable law. Customer will and will
            cause each Authorized User to comply with all applicable laws and complete all
            requirements (including obtaining any necessary export license or other governmental
            approval) prior to exporting, re-exporting, releasing, or otherwise making the Services
            available outside the US.
          </p>
          <p>
            Customer may not transfer (or permit an Authorized User to transfer) any aspect of our
            Services without U.S. government authorization to any entity on a U.S. government
            exclusion list (e.g., the Department of Commerce’s List of Denied Persons, Entity, or
            Unverified List, and the Treasury Department’s List of Specially Designated Nationals
            and Consolidated Sanctions List). Customer represents that Customer is not on a U.S.
            government exclusion list or under the control of or an agent for any entity on such a
            list, and Customer further warrants that Customer will immediately discontinue use of
            the Services if Customer is placed on any such list or under the control of or an agent
            for any entity placed on such a list. Customer represents and warrants that Customer is
            not located in a country that is subject to a U.S. Government embargo or that designated
            by the U.S. Government as a “terrorist supporting” country.
          </p>
          <h2>20. US Government Rights.</h2>
          <p>
            The Documentation is and the Services are a “commercial item” as defined at 48 C.F.R.
            2.101, consisting of “commercial computer software” and “commercial computer software
            documentation” as such terms are used in 48 C.F.R. 12.212. Accordingly, if Customer is
            an agency of the US Government or any contractor therefor, Customer only receives those
            rights with respect to the Services and Documentation as are granted to all other
            customers and users under license, in accordance with (i) 48 C.F.R. §227.7201 through 48
            C.F.R. §227.7204, with respect to the Department of Defense and their contractors, or
            (ii) 48 C.F.R. §12.212, with respect to all other US Government licensees and their
            contractors.
          </p>
          <h2>21. International Users.</h2>
          <p>
            The Services are currently made available to customers from Kensho’s facilities in the
            U.S. Kensho makes no representations that the Services are appropriate or available for
            use outside of the U.S. All parties that access or use the Services from other
            jurisdictions or with recipients of other countries do so at their own volition and are
            responsible for compliance with local law.
          </p>
          <h2>22. Governing Law; Dispute Resolution.</h2>
          <p>
            All matters related to the Services are governed by the internal laws of the State of
            New York, as such laws apply to agreements made and performed therein (without giving
            effect to the principles of conflicts of laws). Customer and Kensho agree to resolve
            disputes arising from or related to these Service Terms or use of the Services in the
            state or federal courts in the Borough of Manhattan, New York, New York. Customer and
            Kensho agree to submit to the jurisdiction of, and agree that venue is proper in, these
            courts in any such legal action or proceeding. The Uniform Computer Information
            Transactions Act does not apply to these Service Terms or to Orders placed under the
            Service Terms.
          </p>
          <h2>23. General.</h2>
          <p>
            <span css={subSectionLabelCss}>23.1</span> <span css={boldCss}>No Waiver.</span>{' '}
            Kensho’s failure to enforce at any time any provision of these Service Terms does not
            waive Kensho’s right to do so later. Any waiver must be in writing and signed by
            Customer and Kensho to be legally binding.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.2</span> <span css={boldCss}>Assignment.</span>{' '}
            Customer will not assign or otherwise transfer these Service Terms, in whole or in part,
            without Kensho’s prior written consent. Any attempt to assign, delegate or transfer in
            violation of this subsection will be null and void. Subject to this Section 23.2, these
            Service Terms are binding on both Customer and Kensho and their successors and assigns.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.3</span> <span css={boldCss}>Relationship.</span>{' '}
            Customer and Kensho are independent contractors in the performance of each and every
            part of these Service Terms. Nothing in these Service Terms is intended to create or
            shall be construed as creating an employer-employee relationship or a partnership,
            agency, joint venture or franchise. Customer and Kensho are and will be solely
            responsible for their respective employees and agents and respective labor costs and
            expenses arising in connection with those employees and agents.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.4</span> <span css={boldCss}>Severability.</span> If
            any provision of these Service Terms is held by a court or other tribunal of competent
            jurisdiction to be unenforceable, that provision will be limited or eliminated to the
            minimum extent necessary to make it enforceable and, in any event, the rest of these
            Service Terms will continue in full force and effect.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.5</span>{' '}
            <span css={boldCss}>Electronic Signatures and Communications.</span> Any signature page
            delivered by confirmed facsimile or electronic image transmission (including in the form
            of a PDF file) is binding to the same extent as an original signature page. The
            communications between Customer and Kensho may use electronic means. Except as
            prohibited by applicable law, Customer consents to receive communications from Kensho in
            an electronic form and agrees that all terms and conditions, agreements, notices,
            disclosures, and other communications that Kensho electronically provides to Customer
            satisfy any legal requirement that such communications would satisfy if it were to be in
            writing.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.6</span> <span css={boldCss}>Notices.</span> Unless
            otherwise expressly required in an Enterprise Agreement, Kensho may provide Customer
            with notice about the Services via email, through the Services or through other
            electronic means that Kensho identifies to Customer. Kensho requires that Customer
            maintain as current the email address in Customer’s Orders. Notice is given as of the
            date it is sent or otherwise made available by Kensho. If the email address is not valid
            for any reason, Kensho’s dispatch of an email containing a legal notice to the email
            address in Customer’s Orders with confirmed delivery will constitute effective notice.
          </p>
          <p>
            Customer may give any notice required under these Service Terms to Kensho by delivering
            it to 44 Brattle Street, Third Floor, Cambridge, Massachusetts 02138 by overnight
            delivery service or first-class postage prepaid mail and sending a copy via email (with
            confirmed delivery) to <EmailLink email={EMAILS.LEGAL} /> with “<i>Legal Notice</i>” in
            the subject line. Any mailed notice shall be deemed given when received by Kensho.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.7</span> <span css={boldCss}>Entire Agreement.</span>{' '}
            Except as provided in these Service Terms (including documents incorporated herein by
            reference), these Service Terms supersede all prior and contemporaneous proposals,
            statements, sales materials or presentations and agreements, oral and written with
            respect to the subject matter hereof. No oral or written information or advice given by
            Kensho, its agents or employees will create a warranty or in any way increase the scope
            of the warranties in these Service Terms.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.8</span> <span css={boldCss}>Force Majeure.</span> No
            failure, delay or default in performance of any obligation of a party shall constitute
            an event of default or breach of these Service Terms to the extent that such failure to
            perform, delay or default arises out of a cause, existing or future, that is beyond the
            control and without negligence of such party, including action or inaction of
            governmental, civil or military authority; fire; strike, lockout or other labor dispute;
            epidemic or pandemic; flood; terrorist act; war; riot; theft; earthquake; or other
            natural disaster. The affected party shall take all reasonable actions to minimize the
            consequences of any such cause.
          </p>
          <p>
            <span css={subSectionLabelCss}>23.9</span> <span css={boldCss}>Anti-Corruption.</span>{' '}
            Neither party has received or been offered any illegal or improper bribe, kickback,
            payment, gift, or thing of value from an employee or agent of the other party in
            connection with these Service Terms.
          </p>
          <h2>QUESTIONS?</h2>
          <p>
            For Customer/Technical Support: <EmailLink email={EMAILS.SUPPORT} />
          </p>
          <p>
            For Privacy Issues: <EmailLink email={EMAILS.PRIVACY} />
          </p>
        </WideContent>
      </section>
    </Page>
  )
}
