import {ClassNames, css} from '@emotion/react'

import caseStudyImg from '../../assets/photos/wall-street-image.jpg'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {h4Body2Css} from '../../styles/common'
import Carousel from '../../components/Carousel'
import CustomerTestimonialSlide, {
  CustomerTestimonialSlideProps,
} from '../../components/Carousel/slides/CustomerTestimonialSlide'
import {TEGUS_SLIDE} from '../Scribe/TestimonialSection'
import CaseStudySlide, {CaseStudySlideProps} from '../../components/Carousel/slides/CaseStudySlide'

const marginCss = css`
  margin-bottom: 10px;
`

const fullHeightCss = css`
  height: 100%;
`

type Slide =
  | ({kind: 'testimonial'} & CustomerTestimonialSlideProps)
  | ({kind: 'case-study'} & CaseStudySlideProps)

const slides: Slide[] = [
  {kind: 'testimonial', ...TEGUS_SLIDE},
  {
    kind: 'case-study',
    title: 'Kensho NERD for Financial Research',
    content: (
      <>
        <p css={[h4Body2Css, marginCss]}>
          Kensho NERD is powering academic research at the City University of Hong Kong and
          Wenzhou-Kean University, on the topic of Media Co-coverage and Overreaction in
          Cross-Industry Information Transfers.
        </p>
        <p css={[h4Body2Css, marginCss]}>
          The research team is using NERD to identify tens of thousands of company names mentioned
          in conference call transcripts and media articles, and analyze them, along with stock
          market and other data.
        </p>
        <p css={h4Body2Css}>
          Kensho NERD plays a vital role in identifying media co-coverage and establishing causality
          by spotting the co-covered firms quickly and accurately.
        </p>
      </>
    ),
    imageUri: caseStudyImg,
    imageAlt:
      'A crystalline structure representing how link connects nodes between various data sources.',
  },
  {
    kind: 'testimonial',
    quote: 'Without Kensho NERD, our research study would not have been feasible or relevant.',
    citation: 'Dr. Alex Zhang, Professor, City University of Hong Kong',
  },
  {
    kind: 'testimonial',
    quote:
      'Kensho’s ability to use Extract as the underpinning of the end-product ensured that value to clients was realized and that we have a stable, quality product as defined by our business.',
    citation:
      'Kevin Zacharuk, Associate Director, Product Management at S&P Global Market Intelligence',
  },
]

export default function CustomerTestimonialCarousel(): JSX.Element {
  return (
    <ClassNames>
      {({css}) => (
        <Carousel
          slides={slides}
          slideRenderer={(slide) =>
            slide.kind === 'testimonial' ? (
              <CustomerTestimonialSlide css={fullHeightCss} {...slide} />
            ) : (
              <CaseStudySlide css={fullHeightCss} {...slide} />
            )
          }
          slideKeyGen={(slide) => (slide.kind === 'testimonial' ? slide.quote : slide.title)}
          slideTrayClassName={css`
            width: 90%;
          `}
          buttonTrayClassName={css`
            @media (max-width: ${BREAKPOINT_SMALL}px) {
              display: none;
            }
          `}
          buttonColor="white"
        />
      )}
    </ClassNames>
  )
}
